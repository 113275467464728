// const GATEWAY_URL = `https://esanchaya.com/`;
// const GATEWAY_URL = `https://testing.esanchaya.com/`;
const GATEWAY_URL = `https://preprod.esanchaya.com`;


export const fetchFilterParams = `${GATEWAY_URL}/my_services_api/scheduling/filter_params`;
export const fetchAdsListAll =  `${GATEWAY_URL}/my_services_api/scheduling/fetch_dummy_chart`;
export const submitAds = `${GATEWAY_URL}/my_services_api/scheduling/post_dummy_chart`;
export const fetchRegionUrl = `${GATEWAY_URL}/my_services_api/scheduling/get_regions`;
export const loginApi = `${GATEWAY_URL}/session/auth/login`;
export const editSlugNoUrl = `${GATEWAY_URL}/my_services_api/scheduling/update_slug_number`;
